import './App.css';

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { SessionProvider } from "./chatbot/bot/ChatBot/SessionContext.js";
import Main from './Main.js';

function App() {


  return (
    <SessionProvider>
   <Main/>
   <ToastContainer style={{ width: "400px" }} />
</SessionProvider>
  );
}

export default App;
