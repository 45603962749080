import { createChatBotMessage } from 'react-chatbot-kit';
import BotAvatar from '../BotAvatar/BotAvatar';
import UserAvatar from '../BotAvatar/UserAvatar';
import Review from '../Components/Review';

import './Chatbot.css'

const config = {
    initialMessages: [createChatBotMessage(`Hey buddy! I'm your virtual assistant, you can ask me anything🤗`, {
      widget: 'null',
    })],
    
    botName: "Kreate.Bots",
    customComponents:{
      botAvatar: (props) => <BotAvatar/>,
      userAvatar: (props) => <UserAvatar/>
    },
    widgets:[
      {
        widgetName: 'review',
        widgetFunc: (props) => <Review {...props} />,
      },
    ],
    
  
};

export default config;