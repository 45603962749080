import React, { createContext, useState } from "react";


const SessionContext = createContext();

const SessionProvider = ({ children }) => {
  const [sessionID, setSessionID] = useState(null);
  const [ff, setFf] = useState(null);
  const [compId, setCompId] = useState('');
  const [prompt, setPrompt] = useState('')
  return (
    <SessionContext.Provider value={{ sessionID, setSessionID, ff, setFf, compId, setCompId, prompt, setPrompt  }}>
      {children}
    </SessionContext.Provider>
  );
};

export { SessionProvider, SessionContext };
