import React from 'react';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './Chatbot.css'
import { SessionContext } from "./SessionContext.js";
import { auth, db } from '../../../firebase.js';
import { Link } from 'react-router-dom';
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const [user, setUser] = useState(null);
  const id = process.env.REACT_APP_COMPANY_ID;
  const [userCredits, setUserCredits] = useState(null);
  const { sessionID, compId,prompt, setPrompt  } = useContext(SessionContext);
  const [isLoading, setIsLoading] = useState(false);
  const [copyText,setCopyText]=useState('')
  const [limit,setLimit] = useState(40)
  const [fprompt, setFprompt] = useState('');
  const [uid, setUid] = useState("");
  const [usermail, setUsermail] = useState("");
  const [apicall, setApicall] = useState("openai")
  const [agentid, setAgentid] = useState(null);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      setUser(currentUser);
      setUsermail(currentUser.email);
      setUid(currentUser.uid);
    
      const querySnapshotNew = await getDocs(
        query(
          collection(db, "userdata"),
          where("email", "==", currentUser.email)
        )
      );
  
      if (!querySnapshotNew.empty) {
        const userData = querySnapshotNew.docs[0].data();
        setUserCredits(userData.credits);
      }


      const querySnapshotNew2 = await getDocs(
        query(
          collection(db, "bot-templates"),
          where("company_id", "==", id)
        )
      );
  
      if (!querySnapshotNew2.empty) {
        const botData = querySnapshotNew2.docs[0].data(); // Accessing the data of the first document
        setAgentid(botData.agent_id);
      }










    });
    
 
  
    return () => unsubscribe();
  }, []);
 

const changeCredits = async () => {
  const querySnapshotNew = await getDocs(
    query(collection(db, "userdata"), where("email", "==", user.email))
  );
const documentRef = doc(db, "userdata", querySnapshotNew.docs[0].id);
updateDoc(documentRef, {
credits: userCredits - 1,
});

setUserCredits((prev) => prev - 1);
}
  const handleCopy=()=>{
      navigator.clipboard.writeText(copyText)
  }



  const askAction = async (msg) => {

  // OpenAI api by Dev  



      let replacedPrompt = prompt;
      replacedPrompt = replacedPrompt.replace(/{userPrompt}/g, msg);
    // alert(replacedPrompt)
    //   setFprompt(replacedPrompt);
    setIsLoading(true);



    if(userCredits !== null && userCredits !== 0){
      if(apicall=='openai'){   
        const formData = new FormData();
        formData.append("name", usermail);
        formData.append("filename", compId);
        formData.append("id", uid);
        formData.append("prompt", replacedPrompt);
        axios.post("https://openai-gemini-chatbot-api-5wz7dep6ya-uc.a.run.app/openai", formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(async (response) => {
        setIsLoading(false);
        const message = createChatBotMessage(response.data.answer, {
          widget:'review',
        });
        setCopyText(response.data.answer)
        saver(message);
        updateState(message);
        changeCredits();
    
      })
      .catch((error) => {
        console.error('Error', error);
      });
    }
    
    else{
    
    
    
     
        //Gemini api by Gaurav
    
        console.log(replacedPrompt)
        const apiUrl = 'https://gemini-search-chat-vtoo6mbt4q-uc.a.run.app/gemini';
      
        try {
          const response = await axios.get(apiUrl, {
            params: {
              prompt: replacedPrompt,
              agent_id: agentid,
            },
          });
          setIsLoading(false);
    
              const message = createChatBotMessage(response.data.answer[0], {
          widget:'review',
        });
        updateState(message);
        changeCredits();
          console.log('Gemini API Response:', response.data.answer[0]);
        } catch (error) {
          console.error('Error fetching Gemini API:', error);
        }
    
      }



    }
    else{
      
      const message = createChatBotMessage("Oops! You are out of credits", {
        widget:'null',
      });
      updateState(message);
      setIsLoading(false);
    }












  };


  const updateState = (message, checker) => {
    
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      checker,     
    }))    
  };

  const saver = async (message) => {
    await saveChatHistory({
      id: message.id,
      message: message.message,
      type: message.type,
      loading: message.loading,
      widget: message.widget,
    });
  };

  const saveChatHistory = async (obj) => {
    if (user && sessionID) {
      try {
        await axios.post(
          "https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/saveSession",
          {
            userEmail: user.email,
            sessionID,
            obj,
          }
        );
      } catch (error) {
        console.error("Error saving chat history:", error);
      }
    }
  };


  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleCopy,
            askAction,
          },
        });
      })}




<div className="range">

<div class="row" style={{width:'max-content', marginLeft:'-24%'}}>
  <div class="col-sm-6 mb-3 mb-sm-0">
    <div class="card" style={{width:'max-content'}}>
      <div class="card-body">
        <h7 class="card-title" style={{width:'max-content', fontFamily: "OCR A Std, monospace", color: "black"}}> 
          Available Credits: {userCredits ? userCredits : 0}{" "}
        </h7>
        <br/><br/>
        <p class="card-text">        <p style={{ color: "grey" }}>
          Dont have enough credits?{" "}
          <Link to="/creditsPricing">
            Click to Buy Credits!
          </Link>
        </p></p>
      </div>
      <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
  <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked={apicall === "openai"} onChange={() => setApicall("openai")} />
  <label class="btn btn-outline-primary" for="btnradio1">OpenAI</label>
  <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" checked={apicall === "gemini"} onChange={() => setApicall("gemini")} />
  <label class="btn btn-outline-primary" for="btnradio3">Gemini</label>
</div>
    </div>

  </div>




  
<header style={{ paddingTop: "20px", paddingLeft: "20px" }}>


      </header>
</div>




    {/* <div class="btn-group" role="group" aria-label="Basic radio toggle button group" style={{width:'50px'}}>
  <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked onClick={()=>{setApicall("openai")}}/>
  <label class="btn btn-outline-primary" for="btnradio1">OpenAI</label>

  <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" onClick={()=>{setApicall("gemini")}}/>
  <label class="btn btn-outline-primary" for="btnradio2">Gemini</label>
</div> */}


</div>


          
{isLoading && (
  <div class="shapes"></div>
)}


    </div>
  );
};

export default ActionProvider;

