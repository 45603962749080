import './BotAvatar.css'
import { BsRobot } from "react-icons/bs";
const BotAvatar = () => {
    return ( 
        <div>
            <BsRobot className='bav'/>
        </div>
     );
}
 
export default BotAvatar;