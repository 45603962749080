import './Form.css'
// import Brand from '../Brand.png'
import axios from 'axios';
import * as bootstrap from 'bootstrap';
import { auth, db } from '../firebase'
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import image from '../PngItem_46390.png'

import { useState, useEffect } from 'react';

const Form = () => {


  const id = process.env.REACT_APP_COMPANY_ID;

    // State to store API response data
    const [apiResponse, setApiResponse] = useState({ companies: [] });

    // State to manage selected company
    const [selectedCompany, setSelectedCompany] = useState('');
  
    // Function to handle dropdown change
    const handleDropdownChange = (event) => {
      setSelectedCompany(event.target.value);
    };

  const [companyName, setCompanyName ] = useState('');
  const [user, setUser] = useState(null);
  const [companyId, setCompanyId] = useState("");
  const [company, setCompany] = useState([]);
  const [uid, setUid] = useState("");
  const [username, setUsername] = useState("");
  const [usermail, setUsermail] = useState("");
  const [userId, setUserId] = useState("vish123");
    const [questions, setQuestions] = useState([]);
    const [userAnswers, setUserAnswers] = useState([]);
    const[floral, setFloral] = useState(false);
const [gDriveLink, setGDriveLink] = useState('');

const [selectedOption, setSelectedOption] = useState('Select Company');

useEffect(() => {
  const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
    if (currentUser) {
      setUser(currentUser);
    }

    const querySnapshotNew = await getDocs(
      query(
        collection(db, "bot-templates"),
        where("company_id", "==", id)
      )
    );

    if (!querySnapshotNew.empty) {
      const botData = querySnapshotNew.docs[0].data(); // Accessing the data of the first document
      setCompanyName(botData.company_name);
      setCompanyId(botData.company_id)
    } else {
      console.log("No documents found in bot-templates collection for company_id: nutriblend");
    }
  });

  return () => unsubscribe();
}, []);


    const toastTrigger = document.getElementById('liveToastBtn')
    const toastLiveExample = document.getElementById('liveToast')
    
    if (toastTrigger) {
      const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
      toastTrigger.addEventListener('click', () => {
        toastBootstrap.show()
      })
    }

    const toastTrigger2 = document.getElementById('liveToastBtn2')
    const toastLiveExample2 = document.getElementById('liveToast2')
    
    if (toastTrigger2) {
      const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample2)
      toastTrigger2.addEventListener('click', () => {
        toastBootstrap.show()
      })
    }

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((currentUser) => {
        if (currentUser) {
          setUser(currentUser);
          setUid(currentUser.uid);
          setUserId(currentUser.uid);
          setUsername(currentUser.displayName) 
          setUsermail(currentUser.email)

        }
      });



  
      return () => unsubscribe();
    }, []);

    const uploadFunction = (name, id) => {
      setSelectedOption(name);
      setCompanyId(id)
      setFloral(true)
    }

    const handleAnswerChange = (question, event) => {
      const updatedUserAnswers = [...userAnswers];
      const existingAnswerIndex = updatedUserAnswers.findIndex(
        (answer) => answer.question === question
      );
  
      if (existingAnswerIndex !== -1) {
        updatedUserAnswers[existingAnswerIndex].answer = event.target.value;
      } else {
        updatedUserAnswers.push({ question, answer: event.target.value });
      }
  
      setUserAnswers(updatedUserAnswers);
    };

    const handleGDriveLinkChange = (event) => {
      setGDriveLink(event.target.value);
    };
  
    const handleSaveAnswers = async () => {

      try {
        const response = await fetch("https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/user-answers", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId, companyId, answers: userAnswers }),
        });
  
        if (response.ok) {
          console.log("User answers saved successfully!");
       
        } else {
          const errorResponse = await response.json();
          console.error(
            "Failed to save user answers. Server response:",
            errorResponse
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    useEffect(() => {
      const fetchCompanies = async () => {
        try {
         
          const response = await axios.get(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/getCompaniesByUserId/${usermail}`);
          

          setCompany(response.data.companies)
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchCompanies();


    }, [usermail]);


    const [userData, setUserData] = useState(null);

    useEffect(() => {
      // Function to fetch data from your API
      const fetchData = async () => {
        try {
          const response = await fetch(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/questions-answers?userId=${uid}&companyId=${companyId}`);
          const data = await response.json();
          setUserData(data.questionsAnswers);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      // Call the fetch function
      fetchData();
    }, []);


   


    useEffect(() => {
      

      const fetchQuestions = async () => {
        try {
          const response = await axios.get(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/questions/${companyId}`);
          // Extract the questions from the API response
          if (response && response.data && response.data.data) {
            setQuestions(response.data.data);
          }
        } catch (error) {
          console.error('Error fetching questions:', error);
        }
      };
  
      fetchQuestions();
    }, [companyId]); 
    

    const handleSaveFile = async () => {
      const formData = new FormData();
      formData.append('name', userId);
      formData.append('id', companyId);
      formData.append('url', gDriveLink); // Use the state value here
      formData.append('email', usermail);
  
      try {
        const response = await axios.post('https://customer-chatbot-gdrive-api-5wz7dep6ya-uc.a.run.app/gdrive', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
  
        if (response.status === 200) {
          console.log(response.data.bucket);
          console.log(response.data);

          console.log('File uploaded successfully!');
          // Perform any actions upon successful file upload
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        // Handle error scenario
      }
    }


    return (
        <div className='form'>
    <div style={{paddingLeft:"3vw", paddingTop:"3vw",width:"100%"}}>
    
  
  
  


{user ? (   
<>

<div className='mainbox'>
<h3 style={{marginLeft:'38%'}}>Profile Setup</h3>
<br/>
<br/>
  <ul className='questions'>
  <div class="floral-design">
    <img src={image} className='imgg'/>

  </div>
  
        {questions.map((question, index) => (
          <li key={index} className='glass' style={{width:'90%'}}>{question}<br/>
            <input type='text' className='answers'  value={
                  userAnswers.find((answer) => answer.question === question)
                    ?.answer || ""
                }
                onChange={(event) => handleAnswerChange(question, event)}/>
         
          </li>
        ))}

      </ul>
                
      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal1" style={{marginLeft:"30%"}}>
  Submit Details
</button>

<div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" style={{width:"30vw"}}>
    <div class="modal-content" style={{textAlign:'center'}}>
        <br/>
     
      <div class="modal-body" style={{textAlign:"center"}}>
        <h5>Do you want to submit?</h5>
      </div>
      <div class="modal-footer" style={{display:"flex", justifyContent:"center"}}>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" onClick={handleSaveAnswers} data-bs-dismiss="modal" id="liveToastBtn">Submit</button>
      </div>
    </div>
  </div>
</div>


   







        </div>
        


   
        <div className='mainbox' style={{paddingLeft:'30%', paddingRight:'30%'}}>
          <h3>Google Drive Link</h3>
        <div class="input-group mb-3" style={{marginTop:'15vh'}}>
  <span class="input-group-text" id="inputGroup-sizing-default">G-Drive Link</span>
  <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" onChange={handleGDriveLinkChange}/>
</div>
<br/>
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal2" style={{marginLeft:"30%"}}>
  Submit file
</button>
</div>
    


        </>
        
) : (
  <div style={{display:"flex", marginTop:"15%"}} className='brandname'>
    <div style={{width:"10vw"}}>
   {/* <img src={Brand} style={{borderRadius:"10px", width:"100%"}}/> */}
   </div>&nbsp;
   <strong style={{fontSize:"6vh"}}>{companyName}</strong>
   
   </div>

)}


    
    </div>  



<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" style={{width:"30vw"}}>
    <div class="modal-content" style={{textAlign:'center'}}>
        <br/>
     
      <div class="modal-body" style={{textAlign:"center"}}>
        <h5>Do you want to submit?</h5>
      </div>
      <div class="modal-footer" style={{display:"flex", justifyContent:"center"}}>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" onClick={handleSaveFile} data-bs-dismiss="modal" id="liveToastBtn2">Submit</button>
      </div>
    </div>
  </div>
</div>





<div class="toast-container position-fixed bottom-0 end-0 p-3">
  <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
  
      <strong class="me-auto" style={{color:"green"}}>Success!</strong>
      <small>Just now</small>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      Your details have been saved!
    </div>
  </div>
</div>

<div class="toast-container position-fixed bottom-0 end-0 p-3">
  <div id="liveToast2" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
  
      <strong class="me-auto" style={{color:"green"}}>Success!</strong>
      <small>Just now</small>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      Your file has been uploaded!
    </div>
  </div>
</div>



                <br/><br/>
    </div>
    
    );
}
 
export default Form;