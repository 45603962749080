import { FaUserAstronaut } from "react-icons/fa";
import './BotAvatar.css';

const UserAvatar = () => {
    return ( 
        <div>
            <FaUserAstronaut className="bav"/>
        </div>
     );
}
 
export default UserAvatar;