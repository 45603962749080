import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
import { MdOutlineFlag, MdContentCopy } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";

import './Components.css'
import { useState } from "react";


const Review = (props) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };
    return ( 
        <div>
        <div>
          <div className="r-icon">
            <div className="oicon">
              <FiThumbsUp className="f-icon" />
              <div className="hoverfIcon"><p>Like the response :)</p></div>
            </div>
            <div className="oicon">
              <FiThumbsDown className="s-icon" />
              <div className="hoversIcon"><p>Dislike the response :(</p></div>
            </div>
            <div className="oicon">
              <MdOutlineFlag  className="t-icon"/>
              <div className="hovertIcon"><p>Report</p></div>
            </div>

            <div className="oicon" onClick={()=>props.actions.handleCopy()}>
              <MdContentCopy  className="t-icon"/>
              <div className="hovertIcon"><p>Copy</p></div>
            </div>

            <div className="oicon">
            {dropdownOpen && (
          <div className="dropdown">
           
              <div className="doptions">Unnecessary info</div>
              <div className="doptions">Incomplete answer</div>
              <div className="doptions">Use formal language</div>
          
           
          </div>
        )}
              <BsThreeDotsVertical  className="t-icon"  onClick={handleDropdownClick}/>
              <div className="hovertIcon"><p>More</p></div>
              
            </div>
          </div>

        
         
        </div>

        
      </div>
     );
}
 
export default Review;