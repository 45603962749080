import { PaymentElement } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { auth, db } from "../../../firebase";

export default function CreditCheckoutForm(props) {
  const [user, setUser] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const url = localStorage.getItem("url");

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [credits, setCredits] = useState(0);

  const [searchParams] = useSearchParams();
  let pricingPlan = searchParams.get("pricingPlan");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      setUser(currentUser);
      let credits;

      if (pricingPlan === "standard") {
        credits = 10;
      } else if (pricingPlan === "startup") {
        credits = 100;
      } else {
        credits = 1000;
      }

      setCredits(credits);
    });

    return () => {
      unsubscribe();
    };
  }, [pricingPlan]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion`,
      },
      redirect: "if_required",
    });

    if (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      toast.success(
        `Successfully purchased credits plan ${props.pricingPlan}, added ${credits} credits to your account!!`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );

      const querySnapshotNew = await getDocs(
        query(collection(db, "userdata"), where("email", "==", user.email))
      );

      if (!querySnapshotNew.empty) {
        const documentRef = doc(db, "userdata", querySnapshotNew.docs[0].id);

        await updateDoc(documentRef, {
          credits: credits,
        });
      }
      navigate("/my-account");
    } else {
      toast.error(`An unexpected error occurred.Please try again later`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setMessage("An unexpected error occurred.");
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit} style={{marginLeft:"8%", marginTop:"4%"}}>
      <PaymentElement id="payment-element" />

      <div className="text-center mt-2">
        <button
          disabled={isProcessing || !stripe || !elements}
          id="submit"
          className="btn btn-primary"
        >
          <span id="button-text">
            {isProcessing ? "Processing ... " : "Pay now"}
          </span>
        </button>
      </div>
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message" className="bg-danger text-white">
          {message}
        </div>
      )}
    </form>
  );
}