import React, { useEffect, useState, useContext } from "react";
import { SessionContext } from "./SessionContext.js";
import { auth } from "../../../firebase";
import axios from "axios";

const MessageParser = ({ children, actions }) => {
  const { checker } = children.props.state;
  const user = auth.currentUser;
  const { sessionID, setSessionID } = useContext(SessionContext);
  const [ses, setSes] = useState('');
  const [newc, setNewc] = useState(true);
  const [chatHistory, setChatHistory] = useState([]);
  const {ff, setFf} = useContext(SessionContext);
  


  const saveChatHistory = async (message) => {
    if (user && sessionID) {
      try {
        await axios.post(
          "https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/saveSession",
          {
            userEmail: user.email,
            sessionID,
            message,
            type : "user"
          }
        );
      } catch (error) {
        console.error("Error saving chat history:", error);
      }
    }
  };

  const parse = async (message) => {
   
    await saveChatHistory({ message: message, type: "user", loading: false });
    actions.askAction(message);
    


  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {  
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;